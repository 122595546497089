.StyledTextField {
  .css-bpt0m5-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 0;
    /* border-color: 1px solid rgba(224, 224, 224, 1); */
    border-color: none;
  }
  /* .css-1d3z3hw-MuiOutlinedInput-notchedOutline  { */
    .MuiOutlinedInput-notchedOutline{
    /* border-left: 1px solid rgba(224, 224, 224, 1); */
    /* border-right: 1px solid rgba(224, 224, 224, 1); */
    border: none;
  }
  .MuiInputBase-root {
    border-left: 0px solid rgba(224, 224, 224, 1);
    border-right: 1px solid rgba(224, 224, 224, 1);
  }
}

.MyBorderClass {
  border-left: 0px solid rgba(224, 224, 224, 1);
  border-right: 1px solid rgba(224, 224, 224, 1);
}

.textClass {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 13px;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
}

.myclass {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
  vertical-align: top;
  width: 100%;
  /* borderChange */
  /* border-left: 0px solid rgba(224, 224, 224, 1); */
  /* border-right: 1px solid rgba(224, 224, 224, 1); */
}

.myclass2 {
  /* padding: 9px; */
}

.myclass3 {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  padding: 8.5px 0px;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 13px;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  padding-left: 10px;
}


/* .mypadding {
  padding: 8.5px 14px;
} */
.indexClass{
  text-align: center;
  padding-left:0px
}


.MuiInputBase-root{
  padding: 0px;
}



.MuiAutocomplete-option{
  font-size: 13px;
  
}




/*----------- customize table styles----------------- */

.line-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.line {
  flex-grow: 1;
  height: 1px;
  background-color: black; /* Adjust the color as needed */
  margin: 0 10px; /* Adjust the space between the line and text */
}

.text {
  white-space: nowrap; /* Prevent text from wrapping */
}



.VBTextField {
  .MuiFormControl-root{
    border-radius: 0;
    /* border-color: 1px solid rgba(224, 224, 224, 1); */
    border-color: none;
  }
  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    /* border-left: 1px solid rgba(224, 224, 224, 1); */
    /* border-right: 1px solid rgba(224, 224, 224, 1); */
    border: none;
  }
  .MuiInputBase-input{
    -webkit-text-fill-color:none;
  }
  .Mui-disabled{
    -webkit-text-fill-color:none;
  }
  input{
    -webkit-text-fill-color:none;
  }
  .MuiInputBase-root{
    background-color: #F3F6F9;
  }

}


