.image{
    width: 60px;
    height: 60px;
    padding: 5px;
}

.popup-image{
    width: 150px;
    height: 150px;padding: 2;
    border: 1px solid black;

}