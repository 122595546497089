/* Vertical scrollbar */
::-webkit-scrollbar {
  width: 5px; /* Adjust width for vertical scrollbar */
  height: 5px; /* Adjust width for vertical scrollbar */
}

::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 15px;
}

::-webkit-scrollbar-thumb {
  background: #0a9ef3;
  border-radius: 15px;
}

/* Horizontal scrollbar */
::-webkit-scrollbar-horizontal {
  height: 5px; /* Adjust height for horizontal scrollbar */
}

::-webkit-scrollbar-thumb:horizontal {
  background: #0a9ef3;
}

@media print {
  iframe {
    display: none;
  }
}

@keyframes pulseGreen {
  0% {
      box-shadow: 0px 0px 5px rgba(0, 255, 0, 0.5);
      transform: scale(1);
  }
  100% {
      box-shadow: 0px 0px 15px rgba(0, 255, 0, 1);
      transform: scale(1.05);
  }
}

@keyframes pulseRed {
  0% {
      box-shadow: 0px 0px 5px rgba(255, 0, 0, 0.5);
      transform: scale(1);
  }
  100% {
      box-shadow: 0px 0px 15px rgba(255, 0, 0, 1);
      transform: scale(1.05);
  }
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px); /* Start slightly above */
  }
  to {
    opacity: 1;
    transform: translateY(0); /* Move to normal position */
  }
}
@font-face {
  font-family: 'saudi_riyal';
  src: url('fonts/saudi_riyal.woff2') format('woff2'),
       url('fonts/saudi_riyal.woff') format('woff'),
       url('fonts/saudi_riyal.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}