.alert-enter {
  opacity: 0;
  transform: translateY(-200%);
}
.alert-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transform: translateY(-200%);
  transition: opacity 300ms, transform 300ms;
}



.quickMenu-enter {
  opacity: 1;
  transform: translateY(200%);
  transition: opacity 300ms, transform 300ms;
}
.quickMenu-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}
.quickMenu-exit {
  opacity: 1;
}
.quickMenu-exit-active {
  opacity: 0;
  transform: translateY(-200%);
  transition: opacity 300ms, transform 300ms;
}