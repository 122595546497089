/* Default Cropper Styles */
.cropper-container {
    position: relative;
  }
  
  /* Round Cropper Styles */
  .cropper-container.round .cropper-crop-box,
  .cropper-container.round .cropper-view-box {
    border-radius: 50%;
  }
  
  .cropper-container.round .cropper-view-box {
    box-shadow: 0 0 0 1px #39f;
    outline: 0;
  }
  
  .cropper-container.round .cropper-face {
    background-color: inherit !important;
  }
  
  .cropper-container.round .cropper-dashed,
  .cropper-container.round .cropper-point.point-se,
  .cropper-container.round .cropper-point.point-sw,
  .cropper-container.round .cropper-point.point-nw,
  .cropper-container.round .cropper-point.point-ne,
  .cropper-container.round .cropper-line {
    display: none !important;
  }
  
  .cropper-container.round .cropper-view-box {
    outline: inherit !important;
  }
  